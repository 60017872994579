import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as firebase from 'firebase';
import 'bootstrap/dist/css/bootstrap.css';

const firebaseConfig = {
    apiKey: "AIzaSyABj7kU-VuBX3UDAofvhYMTIqiVgQBPAZQ",
    authDomain: "willdavenport-d15db.firebaseapp.com",
    databaseURL: "https://willdavenport-d15db.firebaseio.com",
    projectId: "willdavenport-d15db",
    storageBucket: "",
    messagingSenderId: "268666168702",
    appId: "1:268666168702:web:690ddc1eb06d4674"
};

firebase.initializeApp(firebaseConfig);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
