import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import Fun from './components/fun';

class App extends Component {
  state = {  }
  render() { 
    return ( 
      <div className="App">
        <Fun></Fun>
      </div>
     );
  }
}
 
export default App;