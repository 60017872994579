import React, { Component } from 'react';

class EchoChamber extends Component {
    state = {  

    }
    render() { 
        if(this.props.echoText)
            return(
                <div style={{alignContent:'center'}}>
                    <br/>
                    <div className="card text-white bg-dark mb-3" style={{maxWidth: '30rem',alignContent:'center',textAlign:'center'}}>
                    <div className="card-header">The Echo Chamber</div>
                    <div className="card-body">
                        <h5 className="card-title"></h5>
                        <p className="card-text">{this.props.echoText}</p>
                    </div>
                    </div>
                </div>
            );
        return (
            <div>

            </div>
          );
    }
}
 
export default EchoChamber;