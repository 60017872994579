import React, { Component } from 'react';
import ComplaintForm from './complaintForm';

class Fun extends Component {
    state = {  }
    render() { 
        return ( 
            <div>
                <h1 style={{color:'blue'}}>This is Will Davenport's personal website, welcome!</h1>
                <ComplaintForm></ComplaintForm>
            </div>
         );
    }
}
 
export default Fun;