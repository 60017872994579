import React, { Component } from 'react';
import EchoChamber from './echoChamber';

class ComplaintForm extends Component {
    state = {  }
    
    constructor(props) {
        super(props);
        this.state = {echoText: '',submittedEchoText:''};
    
        this.handleChange = this.handleChange.bind(this);
        this.submitEcho = this.submitEcho.bind(this);
    }
    
    submitEcho= () =>{
        console.log('submitting echo: '+ this.state.echoText );
        this.setState({submittedEchoText: this.state.echoText})
    }

    handleChange(event) {
        this.setState({echoText: event.target.value});
    }
    
    render() { 
        return ( 
            <div>
                <div>
                    <div className='form-group'>
                        <br/>
                        <h5>Questions, Comments, Concerns?</h5>
                        <input type='text' value={this.state.echoText} onChange={this.handleChange} style={{width:'500px',padding:'10px'}} placeholder="Example: There's really nothing here..." /><br/>
                        <button onClick={this.submitEcho} className="btn btn-primary" >Will This Actually Go Anywhere?</button>
                    </div>
                </div>
                <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                    <EchoChamber
                    
                        echoText={this.state.submittedEchoText}
                    />
                </div>
               
            </div>
         );
    }
}
 
export default ComplaintForm;